import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
      var linkedIn = this.props.data.social[0].url;
    }

    return (
      <section id="contact">

         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>

            <div className="ten columns">

                 <p className="lead">{message}</p>

     					   <h4>Address, Phone and Email</h4>
     					   <p className="address">
     						   {name}<br />
     						   {city}, {state} {zip}<br />
                    <span>{phone}</span><br />
                    <span>{email}</span><br />
                    <span>LinkedIn: <a target="_blank" rel="noopener noreferrer" href={linkedIn}>{linkedIn}</a></span>
     					   </p>
            </div>

         </div>
   </section>
    );
  }
}

export default Contact;
